import React from "react"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"

import Terms from "../../components/sections/terms"
import Footer from "../../components/sections/footer"
import GetStarted from "../../components/sections/getstarted"

const TermsPage = () => (
  <Layout>
    <SEO title="Terms and Conditions" />
    <Navigation />
    <Terms />
    <GetStarted />
    <Footer />
  </Layout>
)

export default TermsPage
