import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Container, Section } from "../global"

const Terms = () => (
  <StyledSection>
    <GetStartedContainer>
      <GetStartedTitle>Privacy Policy</GetStartedTitle>
      <Subtitle>Effective date: January 1, 2021</Subtitle>
        <p>
            At Speech Therapy Ai, Inc. (“STAi,” “we,” or “us”), our most important value is ‘Patients First’, which means that we are committed to protecting your privacy and we take great care with your personal information that we gather when you access or use Speechtherapy.ai and related websites, applications, and services owned and operated by STAi and that link to this Privacy Policy (collectively, the “Services”). This Privacy Policy is meant to help consumers that use our Services to receive care from their providers or continue care at home (“Consumers”) and providers and health systems that use our tele-health services (“Clients,” and collectively with Consumers, “you,” or “your”) understand how we treat your personal information. BY USING OR ACCESSING THE SERVICES IN ANY MANNER, YOU ACKNOWLEDGE THAT YOU ACCEPT THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY, AND YOU HEREBY CONSENT THAT WE WILL COLLECT, USE, AND SHARE YOUR INFORMATION IN THE FOLLOWING WAYS. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY, YOU MAY NOT USE THE SERVICES. IF YOU USE THE SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORIZED BY SUCH INDIVIDUAL OR ENTITY TO ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF. A Consumer’s use of STAi’s Services is at all times subject to the Agreement (as the term “Agreement” is defined in our Terms and Conditions, which incorporates this Privacy Policy). You may print a copy of this Privacy Policy by clicking here. You can also learn more at speechtherapy.ai/trust. Thank you so much for choosing STAi.
        </p>
        <h2>
            Privacy Policy Table of Contents
        </h2>
        <ul>
            <li>
                HIPAA and PHI
            </li>
            <li>
            Personal Data
                <ul>
                    <li>
                    Client Personal Data Categories of Personal Data We Collect Categories of Sources of Personal Data Commercial or Business Purposes for Collecting Data How We Share Your Personal Data
                    </li>
                    <li>
                    Consumer Personal Data Categories of Personal Data We Collect Categories of Sources of Personal Data Commercial or Business Purposes for Collecting Data How We Share Your Personal Data
                Tracking Tools, Advertising and Opt-Out
                    </li>
                </ul>
            </li>
            <li>
            Data Security and Retention
            </li>
            <li>
            Children’s Privacy
            </li>
            <li>
            How We Use Information That is Neither Personal Data nor PHI
            </li>
            <li>
            Controlling Your Personal Data & Notifications
            </li>
            <li>
            California Rights
            </li>
            <li>
            Changes to this Privacy Policy
            </li>
            <li>
            Contact Information
            </li>
            <li>
            Changes to this Privacy Policy
            </li>
            <li>
            Changes to this Privacy Policy
            </li>
        </ul>

        <div>
            <h3>HIPAA and PHI</h3>
            <p>
            Certain demographic, health and/or health-related information that STAi collects about Consumers as part of providing the Services to our Clients may be considered “protected health information” or “PHI” under the Health Insurance Portability and Accountability Act (“HIPAA”). Specifically, when STAi, acting as a “Business Associate” (as such term is defined in HIPAA) receives identifiable information about a Consumer from or on behalf of a Client, or Customer’s speech therapists, doctor, or other healthcare specialist, professional, provider, organization or agent or affiliate thereof (collectively, “Healthcare Providers”), this information is considered PHI. Personal data that a Consumer provides to STAi outside of the foregoing context is not PHI. For example, when you provide information directly to us, such as when creating an account or using our interactive tools and services, completing medical history forms (“Medical History Forms”); or when you voluntarily provide information in free-form text boxes through the Services or through responses to surveys and questionnaires, or post reviews; or when you send us an email or otherwise contact us, that information is not PHI.
            </p>
            <p>
            HIPAA provides specific protections for the privacy and security of PHI and restricts how PHI is used and disclosed. STAi may only use and disclose PHI in the ways permitted by a Consumer’s Healthcare Provider(s) or authorized by a Consumer.
            </p>
        </div>

        <div>
            <h3>
            Personal Data
            </h3>
            <p>
                The following subsections detail the categories of Personal Data that we may collect over time. “Personal Data” means any information that identifies or relates to a particular individual and also includes information referred to as “personally identifiable information” or “personal information” under applicable data privacy laws, rules or regulations. For each category of Personal Data, these subsections also set out the source of that Personal Data, our commercial or business purpose for collecting that Personal Data and the categories of third parties with whom we share that Personal Data. More information regarding those sources and categories are set forth below.
            </p>
        </div>
        <div>
            <h3>
                Client Personal Data
            </h3>
            <p>
            THE FOLLOWING SUBSECTIONS APPLY ONLY TO CLIENTS. IF YOU ARE A CONSUMER, PLEASE SEE THE CONSUMER PERSONAL DATA SECTION BELOW.
            </p>
            <ul>
                <h4>Categories of Personal Data We May Collect</h4>
                <li>
                Payment Information Examples of Personal Data We May Collect: Payment card type Last four digits of payment card Billing contact Billing email Source: You Third Parties with Whom We May Share Data for Business Purposes: Service Providers

                </li>
                <li>
                Device/IP Information Examples of Personal Data We May Collect: IP address Device ID Domain server Type of device/operating system/browser used to access the Services Source: You Third Parties Third Parties with Whom We May Share Data for Business Purposes: Service Providers Third-Party Business Partners You Access Through the Services
            
                </li>
                <li>
                Web Analytics

                </li>
                <li>
                Examples of Personal Data We May Collect: Web page interactions Referring webpage/source through which you access the Services Non-identifiable request IDs Statistics associated with the interaction between device or browser and the Services

                </li>
                <li>
                Source: You Third Parties

                </li>
                <li>
                Third Parties with Whom We May Share Data for Business Purposes: Service Providers Third-Party Business Partners You Access Through the Services

                </li>
                <li>
                Geolocation Data Source: You Third Parties Third Parties with Whom We May Share Data for Business Purposes: Service Providers Third-Party Business Partners You Access Through the Services

                </li>
                <li>
                Other Identifying Information That You Voluntarily Choose to Provide Examples of Personal Data We May Collect: Unique identifiers such as passwords Personal Data in emails or letters you send to us Personal information that you disclose over the phone Source: You Third Parties with Whom We May Share Data for Business Purposes: Service Providers

                </li>
                <li>
                Client Contact Data Examples of Personal Data We May Collect: First and last name E-mail Phone number Mailing address Source: You Third Parties Third Parties with Whom We May Share Data for Business Purposes: Service Providers Healthcare Providers Insurance Providers Health Information Exchanges Parties You Authorize, Access or Authenticate

                </li>
                <li>
                Client Demographic Data Examples of Personal Data We May Collect: Gender Age Date of birth Zip code Race Ethnicity Source: You Third Parties Third Parties with Whom We May Share Data for Business Purposes: Service Providers Healthcare Providers Insurance Providers Health Information Exchanges Parties You Authorize, Access or Authenticate
            
                </li>
                <li>
                Professional License Information Examples of Personal Data We May Collect: Professional licenses Education history Specialties and certifications Source: You Third Parties Third Parties with Whom We May Share Data for Business Purposes: Service Providers
            
                </li>
            </ul>
        </div>
        <div>
            <h3>
            Categories of Sources of Personal Data
            </h3>
            <ul>
                <li>
                    You When You Provide Information Directly to Us When you create an account. When you send us an email or otherwise contact us. When Personal Data is Automatically Collected When You Use the Services Through Cookies (defined below). If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices. If you download our mobile application or use a location-enabled browser, we may receive information about your location and mobile device, as applicable.
                </li>
                <li>
                    Third Parties Service Providers We may use analytics service providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support. We may use service providers to obtain information to generate leads and create user profiles. Government or Public Records We may use government or other public records for onboarding or verifying Clients.
                </li>
            </ul>
        </div>
        <div>
            <h3>
                Commercial or Business Purposes for Collecting Data
            </h3>
            <ul>
                <li>
                    Providing, Customizing, and Improving the Services Creating and managing your account or other user profiles, billing. Providing you with the products, services and information you request. Meeting or fulfilling the reason you provided the information to us. Providing support and assistance for the Services. Improving the Services, including testing, research, internal analytics, and product development. Personalizing the Services, website content and communications based on your preferences. Fraud protection, security and debugging.
                </li>
                <li>
                    Corresponding with You Responding to correspondence that we receive from you, contacting you when necessary or requested, including to remind you of an upcoming appointment, and sending you information about STAi or the Services. Sending emails and other communications that display content that we think will interest you and according to your preferences including notifying you about certain resources, Healthcare Providers or services.
                </li>
                <li>
                    Legal Requirements Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities. Protecting the rights, property or safety of you, STAi or another party. Enforcing any agreements with you. Responding to claims that any posting or other content violates third-party rights. Resolving disputes.
                </li>
                <li>
                    Onboarding Verification Confirming providers have necessary credentials to practice in the state where advertised.
                </li>
            </ul>
        </div>
        <div>
            <h3>
                How We Share Your Personal Data
            </h3>
            <ul>
                <p>
                    In certain circumstances, we may share your Personal Data with the following categories of service providers and other third parties for the indicated business purposes:
                </p>
                <li>
                    Service Providers Payment Processors Our payment processing partner collects your voluntarily provided payment card information necessary to process your payment. Please see Our payment processing partner’s terms of service and privacy policy for information on its use and storage of personal data. Security and Fraud Prevention Consultants Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity. Hosting, Technology and Communications Providers; Fulfillment Providers; Data Storage Providers; Analytics Providers; Insurance Verification Providers; Staff Augmentation Personnel To perform operational services (such as hosting, billing, fulfillment, data storage, security, insurance verification, web service analytics) and/or make certain services, features or functionality available to our users. Debugging to identify and repair errors that impair existing intended functionality. Short-term, transient use of Personal Data that is not used by another party to build a consumer profile or otherwise alter your consumer experience outside the current interaction. Performing services on our behalf, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing financing, providing advertising or marketing services, providing analytic services, or providing similar services on behalf of the business or service provider. Undertaking internal research for technological development and demonstration. Undertaking activities to verify or maintain the quality or safety of our services.
                </li>
                <li>
                    Selected Recipients Health Information Exchanges Health Information Exchanges and related organizations that collect and organize Consumer information (such as Regional Health Information Organizations) to make your information more securely and easily accessible to your Healthcare Providers. The goal of such organizations is to facilitate access to health information to improve the safety, quality, and efficiency of patient-centered care. More information on Health Information Exchanges can be found here.
                </li>
                <li>
                    Parties You Authorize, Access or Authenticate Third-Party Business Partners You Access Through the Services We will share certain Personal Data if you choose to use any service to log in to the Services. To meet or fulfill the reason you provided the information to us.
                </li>
            </ul>
        </div>
        <div>
            <h3>
                Consumer Personal Data
            </h3>
            <ul>
                <p> 
                    THE FOLLOWING SUBSECTIONS APPLY ONLY TO CONSUMERS. IF YOU ARE A CLIENT, PLEASE SEE THE CLIENT PERSONAL DATA SECTION ABOVE.
                </p>
                <h4>
                    Categories of Personal Data We May Collect
                </h4>
                <li>
                    Payment Information Examples of Personal Data We May Collect: Payment card type Last four digits of payment card Billing contact Billing email Source: You Third Parties with Whom We May Share Data for Business Purposes: Service Providers
                </li>
                <li>
                    Device/IP Information Examples of Personal Data We May Collect: IP address Device ID Domain server Type of device/operating system/browser used to access the Services Source: You Third Parties Third Parties with Whom We May Share Data for Business Purposes: Service Providers Third-Party Business Partners You Access Through the Services
                </li>
                <li>
                    Web Analytics
                </li>
                <li>
                    Examples of Personal Data We May Collect: Web page interactions Referring webpage/source through which you access the Services Non-identifiable request IDs Statistics associated with the interaction between device or browser and the Services
                </li>
                <li>
                    Source: You Third Parties
                </li>
                <li>
                    Third Parties with Whom We May Share Data for Business Purposes: Service Providers Third-Party Business Partners You Access Through the Services
                </li>
                <li>
                    Geolocation Data Source: You Third Parties Third Parties with Whom We May Share Data for Business Purposes: Service Providers Third-Party Business Partners You Access Through the Services
                </li>
                <li>
                    Other Identifying Information That You Voluntarily Choose to Provide Examples of Personal Data We May Collect: Unique identifiers such as passwords Personal Data in emails or letters you send to us Source: You Third Parties with Whom We May Share Data for Business Purposes: Service Providers
                </li>
                <li>
                    Consumer Contact Data Examples of Personal Data We May Collect: First and last name E-mail Phone number Mailing address Source: You Third Parties with Whom We May Share Data for Business Purposes: Service Providers Healthcare Providers Insurance Providers Health Information Exchanges Parties You Authorize, Access or Authenticate
                </li>
                <li>
                    Consumer Demographic Data Examples of Personal Data We May Collect: Gender Age Date of birth Zip code Race Ethnicity Source: You Third Parties with Whom We May Share Data for Business Purposes: Service Providers Healthcare Providers Health Information Exchanges Parties You Authorize, Access or Authenticate
                </li>
                <li>
                    Medical Data Examples of Personal Data We May Collect: Health conditions Healthcare Providers visited Reasons for visit Dates of visit Medical history and health information you provide us Source: You Third Parties with Whom We May Share Data for Business Purposes: Service Providers Healthcare Providers Insurance Providers Health Information Exchanges Parties You Authorize, Access or Authenticate
                </li>
                <li>
                    Insurance Information Examples of Personal Data We May Collect: Insurance carrier Insurance plan Member ID Group ID Payer ID Source: You Third Parties with Whom We May Share Data for Business Purposes: Service Providers Healthcare Providers Health Information Exchanges Parties You Authorize, Access or Authenticate
                </li>
                <li>
                    Session Appointment Data Examples of Personal Data We May Collect: Appointment date/time Provider information Appointment procedure Whether or not user is a new patient for a particular provider Source: You Healthcare Providers Third Parties with Whom We May Share Data for Business Purposes: Service Providers Healthcare Providers Health Information Exchanges
                </li>
                <li>
                    Social Network Data Examples of Personal Data We May Collect: E-mail Phone number Username IP address Device ID Source: You Third Parties Third Parties with Whom We May Share Data for Business Purposes: Service Providers Parties You Authorize, Access or Authenticate
                </li>
                <h4>
                    Categories of Sources of Personal Data
                </h4>
                <li>
                    You When You Provide Information Directly to Us When you create an account or use our interactive tools and services. When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys and questionnaires, or post reviews. When you send us an email or otherwise contact us. When Personal Data is Automatically Collected When You Use the Services Through Cookies (defined below). If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices. If you download our mobile application or use a location-enabled browser, we may receive information about your location and mobile device, as applicable.
                </li>
                <li>
                    Third Parties Service Providers We may use analytics service providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support. We may use service providers to obtain information to generate leads and create user profiles. Healthcare Providers We may receive certain data from your Healthcare Provider or Clients to facilitate Consumer booking of appointments. Social Networks If you provide your social network account credentials to us or otherwise sign in to the Services through a third-party site or service, you understand some content and/or information in those accounts may be transmitted into your account with us.
                </li>
                <h4>
                    Commercial or Business Purposes for Collecting Data
                </h4>
                <li>
                    Providing, Customizing, and Improving the Services Creating and managing your account or other user profiles, billing. Providing you with the products, services and information you request. Meeting or fulfilling the reason you provided the information to us. Providing support and assistance for the Services. Improving the Services, including testing, research, internal analytics, and product development. Personalizing the Services, website content and communications based on your preferences. Fraud protection, security and debugging.
                </li>
                <li>
                    Corresponding with You Responding to correspondence that we receive from you, contacting you when necessary or requested, including to remind you of an upcoming appointment, and sending you information about STAi or the Services. Sending emails and other communications that display content that we think will interest you and according to your preferences including notifying you about certain resources, Healthcare Providers or services.
                </li>
                <li>
                    Legal Requirements Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities. Protecting the rights, property or safety of you, STAi or another party. Enforcing any agreements with you. Responding to claims that any posting or other content violates third-party rights. Resolving disputes.
                </li>
            </ul>
        </div>
        <div>
            <h3>
                How We Share Your Personal Data
            </h3>
            <p>
                In certain circumstances, we may share your Personal Data with the following categories of service providers and other third parties for the indicated business purposes:
            </p>
            <ul>
                <li>
                    Service Providers Payment Processors Our payment processing partner collects your voluntarily provided payment card information necessary to process your payment. Please be aware of the terms of service and privacy policy for information regarding the payment processor’s use and storage of personal data. Security and Fraud Prevention Consultants Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity. Hosting, Technology and Communications Providers; Fulfillment Providers; Data Storage Providers; Analytics Providers; Insurance Verification Providers; Staff Augmentation Personnel To perform operational services (such as hosting, billing, fulfillment, data storage, security, insurance verification, web service analytics) and/or make certain services, features or functionality available to our users. Debugging to identify and repair errors that impair existing intended functionality. Short-term, transient use of Personal Data that is not used by another party to build a consumer profile or otherwise alter your consumer experience outside the current interaction. Performing services on our behalf, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing financing, providing advertising or marketing services, providing analytic services, or providing similar services on behalf of the business or service provider. Undertaking internal research for technological development and demonstration. Undertaking activities to verify or maintain the quality or safety of our services.
                </li>
                <li>
                    Selected Recipients Health Information Exchanges Health Information Exchanges and related organizations that collect and organize Consumer information (such as Regional Health Information Organizations) to make your information more securely and easily accessible to your Healthcare Providers. The goal of such organizations is to facilitate access to health information to improve the safety, quality, and efficiency of patient-centered care. More information on Health Information Exchanges can be found here. Healthcare Providers Healthcare Providers with whom Consumers choose to participate in sessions with through the Services.  In the event of an emergency. Insurance Providers To determine eligibility and cost-sharing obligations, and to otherwise obtain benefit plan information on your behalf.
                </li>
                <li>
                    Parties You Authorize, Access or Authenticate Third-Party Business Partners You Access Through the Services We will share certain Personal Data if you choose to use any service to log in to the Services. To meet or fulfill the reason you provided the information to us. Other Users Any information that you may reveal in a review posting or online discussion or forum is intentionally open to the public and is not in any way private. We recommend that you carefully consider whether to disclose any Personal Data in any public posting or forum. What you have written may be seen and/or collected by third parties and may be used by others in ways we are unable to control or predict. 
                </li>
            </ul>
        </div>
        
        <div>
            <h3>
            THE FOLLOWING SECTIONS APPLY TO BOTH CONSUMERS AND CLIENTS.
            </h3>
            <div>
                <h4>
                    Business Transfers
                </h4>
                <p>
                    All Personal Data may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices.
                </p>
                <h4>
                    Data Security and Retention
                </h4>
                <p>
                    The security of your Personal Data is important to us. We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. We endeavor to follow generally accepted industry standards to protect the Personal Data submitted to us, both during transmission and in storage. For example, the Services use industry standard Secure Sockets Layer (SSL) technology to allow for the encryption of Personal Data. We store and process your information on our servers in the United States. We maintain what we consider industry standard backup and archival systems, full data encryption in transit and at rest, least privileges with fine grain access controls, multiple availability zones and cross regional replication to ensure continued services, all actions are audited and logged. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, for example, by making good faith efforts to store Personal Data in a secure operating environment that is not open to the public, please be aware that no method of transmitting data over the Internet or storing data is completely secure. We cannot and do not guarantee the complete security of any data you share with us, and except as expressly required by law, we are not responsible for the theft, destruction, loss or inadvertent disclosure of your information or content.
                </p>
                <p>
                    If at any time during or after our relationship we believe that the security of your Personal Data may have been compromised, we may seek to notify you of that development. If a notification is appropriate, we will endeavor to notify you as promptly as possible under the circumstances.  If we have your e-mail address, we may notify you by e-mail to the most recent e-mail address you have provided us in your account profile. Please keep your e-mail address in your account up to date. You can update that e-mail address anytime in your account profile. If you receive a notice from us, you can print it to retain a copy of it. To receive these notices, you must check your e-mail account using your computer or mobile device and email application software. You consent to our use of e-mail as a means of such notification. If you prefer for us to use the U.S. Postal Service to notify you in this situation, please e-mail us at support@speechtherapy.ai. Please include your address when you submit your request. You can make this election any time, and it will apply to notifications we make after a reasonable time thereafter for us to process your request. You may also use this e-mail address to request a print copy, at no charge, of an electronic notice we have sent to you regarding a compromise of your Personal Data.
                </p>
                <p>
                    We retain Personal Data about you consistent with all internal policies and procedures. We may retain Personal Data to comply with our legal obligations, resolve disputes or collect fees owed, or as is otherwise permitted or required by our data retention policies and procedures.
                </p>
                <h4>
                    Children’s Privacy
                </h4>
                <p>
                    The Services are not directed to or intended for use by children under the age of majority in your place of residence. If you are a child under the age of majority in your place of residence, please do not attempt to register for or otherwise use the Services or send us any Personal Data. By accessing, using and/or submitting information to or through the Services, you represent that you are not under the age of majority in your place of residence. As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data from children under the age of majority in your place of residence. If we learn that we have received any Personal Data directly from a child under age of majority in your place of residence, without first receiving his or her parent’s verified consent, we will use that Personal Data only to respond directly to that child (or his or her parent or legal guardian) to inform the child that he or she cannot use the Services. We will then subsequently delete that child’s Personal Data. If you believe that a child under age of majority in your place of residence may have provided us with Personal Data, please contact us at support@speechtherapy.ai.
                </p>
                <p>
                    If you are under the age of majority in your place of residence, you may use the Services only with the consent of or under the supervision of your parent or legal guardian. If you are a parent or legal guardian of a minor child, you may, in compliance with the Agreement, use the Services on behalf of such minor child. Any information that you provide us while using the Services on behalf of your minor child will be treated as Personal Data as otherwise provided herein.
                </p>
                <p>
                    If you use the Services on behalf of another person, regardless of age, you agree that STAi may contact you for any communication made in connection with providing the Services or any legally required communications. You further agree to forward or share any such communication with any person for whom you are using the Services on behalf.
                </p>
                <h4>
                    How We Use Information That is Neither Personal Data nor PHI 
                </h4>
                <p>
                    We may use information that is neither Personal Data nor PHI (including non-PHI Personal Data that has been de-identified and/or aggregated) to better understand who uses STAi and how we can deliver a better digital healthcare experience, or otherwise at our discretion.
                </p>
                <h4>
                    Controlling Your Personal Data & Notifications
                </h4>
                <p>
                    If you are a registered user of the Services, you can modify certain Personal Data or account information by logging in and accessing your account. If you wish to close your account, please email us at support@speechtherapy.ai. STAi will use reasonable efforts to delete your account as soon as reasonably possible. Please note, however, that STAi reserves the right to retain information from closed accounts consistent with all internal data retention policies and procedures.
                </p>
                <p>
                    You must promptly notify us if any of your account data is lost, stolen or used without permission.
                </p>
                <h4>
                    California Rights
                </h4>
                <p>
                    The California Consumer Privacy Act of 2018 (“CCPA”) provides California residents with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights. If you have any questions about this section or whether any of the following applies to you, please contact us at support@speechtherapy.ai and indicate “California Rights” in the subject line of your communication.
                </p>
                <h4>
                    Access
                </h4>
                <ul>
                    <p>
                        You have the right to request certain information about our collection and use of your Personal Data over the past 12 months, including the following:
                    </p>
                    <li>
                        The categories of Personal Data that we have collected about you.
                    </li>
                    <li>
                        The categories of sources from which that Personal Data was collected.
                    </li>
                    <li>
                        The categories of third parties with whom we have shared your Personal Data.
                    </li>
                    <li>
                        The specific pieces of Personal Data that we have collected about you.
                    </li>
                </ul>
                <h4>
                    Deletion
                </h4>
                <p>
                    You have the right to request that we delete the Personal Data that we have collected from you. Under the CCPA, this right is subject to certain exceptions: for example, we may need to retain your Personal Data to provide you with the Services or complete a transaction or other action you have requested. If your deletion request is subject to one of these exceptions, we may deny your deletion request.
                </p>
                <h4>
                    Exercising Your Rights
                </h4>
                <p>
                    To exercise the rights described above, you must send us a request that (1) provides sufficient information to allow us to verify that you are the person about whom we have collected Personal Data, and (2) describes your request in sufficient detail to allow us to understand, evaluate, and respond to it. Each request that meets both of these criteria will be considered a “Valid Request.” We may not respond to requests that do not meet these criteria. We will only use Personal Data provided in a Valid Request to verify you and complete your request. You do not need an account to submit a Valid Request.
                </p>
                <p>
                    We will work to respond to your Valid Request within 45 days of receipt. We will not charge you a fee for making a Valid Request unless your Valid Request(s) is excessive, repetitive, or manifestly unfounded. If we determine that your Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing your request.
                </p>
                <p>
                    You may submit a Valid Request using the following methods:

                </p>
                <ul>
                    <li>
                        Emailing us at: support@speechtherapy.ai
                    </li>
                </ul>
                <h4>
                    We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA
                </h4>
                <p>
                    We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our goods or services, charge you different prices or rates, or provide you a lower quality of goods and services if you exercise your rights under the CCPA. However, we may offer different tiers of our Services as allowed by applicable data privacy laws (including the CCPA) with varying prices, rates, or levels of quality of the goods or services you receive related to the value of Personal Data that we receive from you.
                </p>
                <h4>
                    Other California Resident Rights
                </h4>
                <p>
                    Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at support@speechtherapy.ai.
                </p>
                <h4>
                    Changes to this Privacy Policy
                </h4>
                <p>
                    We reserve the right to amend our Privacy Policy at our discretion and at any time. When we make changes to the Privacy Policy, we will notify you by email or through a notice on our website homepage. Use of the information we collect is subject to the Privacy Policy in effect at the time such information is collected.
                </p>
                <h4>
                    Contact Information
                </h4>
                <p>
                    If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data, your choices and rights regarding such use, please do not hesitate to contact us at:
                </p>
                <ul>
                    <li>
                        Email: support@speechtherapy.ai
                    </li>
                    <li>
                        Address: Speech Therapy Ai, Inc., 3423 Piedmont Road NE, Atlanta, Ga 30305
                    </li>
                </ul>
            </div>
        </div>
    </GetStartedContainer>
  </StyledSection>
)

export default Terms

const StyledSection = styled(Section)`
  background-color: #f8f8f8;
  // clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
  
`
const FormSubtitleLink = styled(Link)`
  color: ${props => props.theme.color.secondary};
  padding-bottom: 1px;
  margin-left: 8px;
  text-decoration: none;
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const TryItButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const Subtitle = styled.span`
  ${props => props.theme.font_size.xxsmall}
  padding-top: 16px;
  font-size: 14px;
  color: ${props => props.theme.color.primary};
`
